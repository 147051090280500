<template>
  <div>
    <!-- Table -->
    <b-table
      id="inversionistasTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :filter="filter"
      :filter-function="filterFunction"
      empty-filtered-text="No se encontraron departamentos con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- Identificación -->
      <template #cell(inversionista.identificacion)="row">
        <filtered-row :value="row.item.inversionista.identificacion" :filter="filter"/>
      </template>

      <template #cell(num_catastral)="row">
        <filtered-row :value="row.item.num_catastral" :filter="filter"/>
      </template>

      <template #cell(cuenta_predial)="row">
        <filtered-row :value="row.item.cuenta_predial" :filter="filter"/>
      </template>

      <template #cell(renta_actual)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ $formatNumber(row.item.renta_actual) }}
        </span>
      </template>

      <template #cell(invoice.total_factura)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ $formatNumber(row.item.invoice ? row.item.invoice.total_factura : 0) }}
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="documentosInversionistaModule/getResourceToEdit"
            deletePath="documentosInversionistaModule/deleteResource"
            :editPermissions="['actualizar documentos']"
            :deletePermissions="['eliminar documentos']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="6">
                <b-card
                  header="Información del pago"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-list-group flush>
                    <b-list-group-item v-if="row.item.tipo_persona">
                      <b>Tipo de persona:</b>
                      {{ row.item.tipo_persona }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.rfc">
                      <b>RFC:</b>
                      {{ row.item.rfc }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.regimen_fiscal.descripcion">
                      <b>Régimen fiscal:</b>
                      {{ row.item.regimen_fiscal.descripcion }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.identificacion">
                      <b>Identificación:</b>
                      {{ row.item.identificacion }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.tipo_persona">
                      <b>{{ row.item.tipo_persona == 'FISICA' ? 'Nombre completo' : 'Razón social' }}:</b>
                      {{ row.item.tipo_persona == 'FISICA' ? row.item.persona_fisica.nombre_completo : row.item.persona_moral.razon_social }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>
            </b-row>

            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '../../Common/Table/FilteredRow.vue'
import { formatNumber } from 'chart.js/helpers'

const API_MODULE = 'compareInvoicesModule'

export default {
  name: 'CompareInvoicesList',

  props: {
    filter: {
      required: true
    }
  },

  components: {
    FilteredRow
  },

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'num_catastral', label: 'Num. Catastral', sortable: true },
        { key: 'cuenta_predial', label: 'Cuenta Predial' },
        { key: 'inversionista.identificacion', label: 'Inversionista' },
        { key: 'renta_actual', label: 'Importe Calculado de Renta' },
        { key: 'invoice.total_factura', label: 'Importe Pagado' },
        { key: 'acciones', label: 'Acciones' }
      ],

      perPage: 10,

      currentPage: 1
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ]),
    ...mapGetters('userModule', [
      'isAuthUserOrCloneUser'
    ])
  },

  methods: {
    formatNumber,
    async submit () {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getResource`)

      if (error) this.$notify({ error, message }, 'Documentos')
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return row.num_catastral.toLowerCase().includes(filter.toLowerCase()) ||
        row.cuenta_predial.toLowerCase().includes(filter.toLowerCase()) ||
        (row.inversionista && row.inversionista.identificacion.toLowerCase().includes(filter.toLowerCase()))
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    },

    async onDownloadFile (archivo) {
      console.log(archivo)
      const result = await this.$store.dispatch('archivoModule/download', archivo.id)

      await this.$onDownload(result, archivo.archivo)

      this.$notify({ message: `Descarga de archivo ${archivo.archivo}.` })
    }
  }
}
</script>
