<template>
  <base-view title="Análisis de Importes" icon="list">
    <div>
      <check-authorization :requiresAuthorizations="['indice documentos']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Listado de Departamentos

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Search -->
              <search-bar
                placeholder="Buscar por número catastral, cuenta predial o inversionista"
                @filter="(filter) => this.filter = filter"></search-bar>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getResource(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <compare-invoices-list @on-got-item="showForm=true" :filter="filter"></compare-invoices-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CompareInvoicesList from '../../components/Operaciones/CompareInvoices/CompareInvoicesList.vue'
import SearchBar from '../../components/Common/Views/SearchBar.vue'

export default {
  name: 'CompareInvoicesMainView',

  components: {
    CompareInvoicesList,
    SearchBar
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      filter: ''
    }
  },

  computed: mapGetters('CompareInvoicesModule', ['isEditingResource']),

  methods: {
    ...mapActions('CompareInvoicesModule', ['getResource'])
  }
}
</script>
